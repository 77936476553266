import { Transition } from '@headlessui/react'
import ReviewCard from 'cc/ReviewCard'
import { useEffect, Fragment } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { normalizeReview } from 'common/utils'
const date = new Date()
date.setMonth(date.getMonth() - 1)
date.setSeconds(0)

const domainKey = 'google.com'

const meta = {
  createdAt: date,
  updatedAt: date,
  numPageReviews: 3,
  domainKey,
}

const goodReview = normalizeReview({
  comment:
    'I love this product! *I love this product*! I **love** this product! I [love](http://google.com) this product! I love this product! I love this product! I love this product! I love this product! I love this product! I love this product! I love this product! I love this product! I love this product! I love this product! ',
  vote: 1,
  hashtags: ['Good', 'Product'],
  meta,
})

const badReview = normalizeReview({
  comment: 'I despise this site!',
  vote: -1,
  hashtags: ['Bad', 'BadBadBad'],
  meta,
})

const neutralReview = normalizeReview({
  comment: 'I have conflicted feelings about this site!',
  vote: 0,
  hashtags: ['Good', 'Bad', 'Dunno'],
  meta,
})

const ardencaple = {
  handle: 'ardencaple',
  name: 'Leigh Donovan',
  tagline: "Livin' that local life",
}

const kali = {
  handle: 'kali',
  name: 'Kali Donovan',
  tagline: 'notes as i browse the web',
  avatar:
    'https://lh3.googleusercontent.com/a-/AOh14GhXtqsJsHkBvcQ4vvOlL-9p075xNSEza42shM2I=s96-c',
}

const examples = [
  { review: goodReview, user: kali },
  { review: badReview, user: ardencaple },
  { review: neutralReview, user: kali },
]

const TransitioningCard = ({ show, className, review, user }) => (
  <Transition
    show={show}
    enter="duration-300 ease-in"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-300 ease-out"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-50 scale-95"
    className="z-10 absolute inset-0 flex items-center justify-center p-6"
  >
    <ReviewCard review={review} user={user} className={className} />
  </Transition>
)

const RotatingExampleCards = ({ className }) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((i) => (i + 1) % examples.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return examples.map(({ review, user }, i) => (
    <TransitioningCard
      key={i}
      review={review}
      user={user}
      className={className}
      show={i === index}
    />
  ))
}

export default RotatingExampleCards
