import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

// TODO: *should* we be adding noreferrer? Seems like noopenner is enough, and we DO want domains to see links came from us...
const LinkRenderer = ({ href, children }) => (
  <a href={href} target="_blank" rel="ugc nofollow noopener">
    {children}
  </a>
)

const customComponents = { a: LinkRenderer }

const MarkdownText = ({ text }) => {
  return (
    <ReactMarkdown remarkPlugins={[gfm]} components={customComponents}>
      {text}
    </ReactMarkdown>
  )
}

export default MarkdownText
