import rawImportedDebug from 'debug'
import config from 'common/config'

// TODO: this should be dynamically toggled via env var: https://github.com/visionmedia/debug#readme
rawImportedDebug.enable(`${config.appName}:*`)

export const logger = rawImportedDebug(config.appName)
if (typeof console !== 'undefined') {
  logger.log = console.log.bind(console)
}

export const inspectObject = (input) => JSON.stringify(input, null, 2)
export const inspectObjectHTML = (input) => <pre>{inspectObject(input)}</pre>
