/* eslint-disable @next/next/no-img-element */
import config from 'common/config'
import dynamic from 'next/dynamic'

import Image from 'next/image'
import routes from 'common/routes'
import OpenGraph from 'components/OpenGraph'
import { cn } from 'common/utils'

import { Toaster } from 'react-hot-toast'
import appToastOptions from 'common/config/toast'

import homepagePic from 'public/images/homepage/bg.jpg'
import explanatoryOne from 'public/images/homepage/explanatory-1.png'
import explanatoryTwo from 'public/images/homepage/explanatory-2.png'

import {
  MenuIcon,
  CloseIcon,
  ConnectIcon,
  ShareIcon,
  LearnIcon,
  KnowIcon,
} from 'cc/icons'
import Logo from 'cc/Logo'

import Features from 'components/marketing/Features'
import DefaultFooter from 'components/layouts/DefaultLayout/DefaultFooter'
import GetItHere from 'components/marketing/GetItHere'
import ExplanatorySection from 'components/marketing/ExplanatorySection'
import RotatingExampleCards from 'components/marketing/RotatingExampleCards'
import AboutInstallingExtensionButtonset from 'components/marketing/AboutInstallingExtensionButtonset'

import {
  navLinkConfig,
  NavLink,
} from 'components/layouts/DefaultLayout/TopNavBar'
import CollapsiblePrimaryNav from 'components/layouts/DefaultLayout/TopNavBar/CollapsiblePrimaryNav'

const DynamicLandingPageNavAuthentication = dynamic(
  () => import('components/NavAuthentication/ForLandingPage'),
  { ssr: false },
)

const features = [
  {
    name: 'Know',
    description:
      'Unsure about a webpage? Gain instant, intuitive insights from the people you trust.',
    icon: KnowIcon,
  },
  {
    name: 'Connect',
    description:
      "Your network is totally personal. No bots, no spam, it's 100% in your control.",
    icon: (props) => <ConnectIcon {...props} className="h-7 w-7" />,
  },
  {
    name: 'Share',
    description:
      "It's easy to add your own review of any webpage or domain. Like, literally 3 clicks easy.",
    icon: ShareIcon,
  },
  {
    name: 'Learn',
    description:
      'Protect yourself from fake news and echo chambers with real time feedback from your network.',
    icon: LearnIcon,
  },
]

const explanations = [
  {
    image: explanatoryOne,
    title: (
      <>
        Build Your New{' '}
        <span className="text-blue-600 dark:text-blue-400">Idea</span>
      </>
    ),
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Mollitia iure consectetur dicta tenetur, porro consequuntur saepe accusantium consequatur.',
  },
  {
    image: explanatoryTwo,
    title: 'Know',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Mollitia iure consectetur dicta tenetur, porro consequuntur saepe accusantium consequatur.',
    action: (
      <a
        href="#"
        className="px-5 py-2 font-semibold text-gray-100 transition-colors duration-200  bg-gray-900 rounded-md hover:bg-gray-700"
      >
        Start Now
      </a>
    ),
  },
]

const Home = () => {
  return (
    <div className="h-screen bg-gray-200">
      <OpenGraph
        url={routes.rootUrl}
        title="Home"
        // description={desc} TODO
      />
      <div className="relative overflow-hidden bg-gray-100 shadow">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pb-8 bg-gray-100 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden w-48 h-full text-gray-100 translate-x-1/2 lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10"
                aria-label="Global"
              >
                <Logo className="w-8 md:w-10 lg:w-12" />

                <span className="sr-only">{config.appName}</span>

                <div className="flex-wrap items-center hidden md:flex md:px-4 md:space-x-8">
                  {navLinkConfig.map(({ name, href }) => (
                    <NavLink
                      key={name}
                      href={href}
                      text={name}
                      className="font-medium text-gray-500 hover:text-gray-900"
                    />
                  ))}
                </div>
                <div className="flex-shrink-0 flex-grow md:flex-grow-0 flex justify-end items-center lg:-mr-2">
                  <div className="w-24 text-right">
                    {/* Needs explicit width to avoid jumping when this loads after page has already rendered */}
                    <DynamicLandingPageNavAuthentication onLandingPage />
                  </div>
                  <CollapsiblePrimaryNav
                    links={navLinkConfig}
                    className="relative md:hidden"
                  />
                </div>
              </nav>
            </div>

            <main className="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Stop getting lost</span>{' '}
                  <span className="block text-gray-600 xl:inline">online</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 lg:pr-10 xl:pr-0">
                  Like your own personal Rand McNally, {config.appName} connects
                  you with the people you trust to guide you through the digital
                  frontier.
                </p>

                <AboutInstallingExtensionButtonset />
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-black">
          <div className="relative h-full min-h-[420px]">
            <Image
              src={homepagePic}
              alt="Background image of a network grid"
              className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full grayscale blur-sm"
              priority
              fill
              sizes="100vw"
              style={{
                objectPosition: 'center',
              }}
            />
            <RotatingExampleCards className="bg-white border-gray-200 border rounded-md shadow-xl z-10 m-4" />
          </div>
        </div>
      </div>

      {explanations.map((props, index) => (
        <ExplanatorySection key={index} {...props} reversed={index % 2 === 1} />
      ))}

      <Features features={features} className="bg-gray-300" />
      <GetItHere className="bg-gray-100 border-top" />
      <DefaultFooter className="bg-gradient-to-tr from-gray-400 to-gray-50 border-t border-b -mt-20 w-full pt-[100px] xl:pt-[150px]" />
      <Toaster {...appToastOptions} />
    </div>
  )
}

Home.barePage = true

export default Home
