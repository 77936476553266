import UserAvatarExternal from 'react-user-avatar'
import { handle } from 'common/utils'

const Avatar = ({ user, size = 48, ...props }) =>
  user ? (
    <UserAvatarExternal
      size={size}
      name={user.name || user.handle || 'anonymous'}
      src={user.avatar}
      alt={`${handle(user)}'s avatar`}
      // Note: does NOT accept className prop, as it gets set by underlying library (fork and add cn support?)
      // className="leading-none"

      {...props}
    />
  ) : null

export default Avatar
