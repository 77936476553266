import toast from 'react-hot-toast'
import { EmailIcon } from 'cc/icons'
import { cn } from 'common/utils'

const joinNewsletterApiCall = async (email) => {
  const resp = await fetch('/api/add-to-mailing-list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ email }),
  })

  return resp.json()
}

const doJoinNewsletter = async (event) => {
  event.preventDefault()
  const input = event.target.querySelector('input[name="email"]')
  const email = input.value

  return toast
    .promise(joinNewsletterApiCall(email), {
      loading: 'Joining mailing list...',
      success: `List joined!`,
      error: (error) => {
        console.error('[ERROR] joining mailing list: ', error?.message, error)
        return 'Error joining mailing list'
      },
    })
    .then(() => {
      input.value = ''
      input.placeholder = 'Thanks for subscribing!'
    })
}

const NewsletterForm = (_props) => {
  return (
    <form
      className="flex items-start justify-center w-full"
      onSubmit={doJoinNewsletter}
    >
      <div className="relative mr-4 text-left w-full sm:w-2/3 md:w-1/2 lg:w-1/3">
        <label
          htmlFor="email"
          className="text-sm leading-7 text-gray-600"
        ></label>
        <div className="relative text-gray-400 focus-within:text-gray-500">
          <input
            type="email"
            name="email"
            id="email"
            className="pl-10 w-full pr-3 py-1 text-base leading-8 text-gray-700 focus:text-gray-800 transition-colors duration-200 ease-in-out bg-gray-100 bg-opacity-50 border focus:bg-gray-50 border-gray-300 rounded outline-none focus:ring-2 focus:ring-blue-200 focus:bg-transparent focus:border-blue-500"
          />

          <EmailIcon className="absolute inset-y-1.5 left-1.5 flex items-center pointer-events-none h-8 w-8" />

          <p className="mt-2 text-sm text-gray-500">
            Wanna follow the adventure? Subscribe to our newsletter.
          </p>
        </div>
      </div>
      <button
        type="submit"
        className="inline-flex px-6 py-2 text-lg text-white bg-blue-500 border-0 rounded focus:outline-none hover:bg-blue-600"
      >
        Subscribe
      </button>
    </form>
  )
}

export default NewsletterForm
