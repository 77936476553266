import { cn } from 'common/utils'
import Image from 'next/image'

// Based on ParagraphWithImage from https://merakiui.com/
const ExplanatorySection = ({
  image,
  title,
  description,
  action,
  reversed,
  className,
}) => {
  return (
    <section
      className={cn(
        'bg-gray-100 dark:bg-gray-900 lg:py-12 lg:flex lg:justify-center',
        className,
      )}
    >
      <div
        className={cn(
          'bg-white dark:bg-gray-800 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg lg:flex',
          reversed ? 'lg:flex-row-reverse' : 'lg:flex-row',
        )}
      >
        <div className="lg:w-2/3">
          <Image
            src={image}
            alt="Explanatory image"
            className={cn(
              'h-64 bg-cover lg:h-full',
              reversed ? 'lg:rounded-r-lg' : 'lg:rounded-l-lg',
            )}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>

        <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/3">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
            {title}
          </h2>
          <p className="mt-4 text-gray-600 dark:text-gray-400">{description}</p>

          <div className="mt-8">{action}</div>
        </div>
      </div>
    </section>
  )
}

export default ExplanatorySection
