import Link from 'components/Link'
import routes from 'common/routes'

const AboutInstallingExtensionButtonset = () => {
  return (
    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
      <div className="mt-3 rounded-md shadow sm:mt-0">
        <Link
          href={routes.aboutExtensionPath}
          text="Install Extension"
          className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
        />
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-3">
        <Link
          href={routes.aboutPath}
          text="Learn More"
          className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-blue-700 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
        />
      </div>
    </div>
  )
}

export default AboutInstallingExtensionButtonset
