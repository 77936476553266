import { inspectObject } from './debugging'

export const seconds = 1
export const minutes = 60 * seconds
export const hours = 60 * minutes
export const days = hours * 24
export const weeks = days * 7

// https://stackoverflow.com/questions/27012854/change-iso-date-string-to-date-object-javascript
const parseISOString = (s) => {
  const b = s.split(/\D+/)
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
}

export const dateStringFromDatish = (datish) => {
  const date = dateFromDatish(datish)
  return date?.toISOString ? date.toISOString() : null
}

export const dateFromDatish = (datish) => {
  if (datish instanceof Date) return datish
  if (!datish) return null
  if (datish instanceof Object && Object.keys(datish).length === 0) return null

  // Firestore timestamps have a toDate method...
  if (datish.toDate) return datish.toDate()

  // ...but not after passing through proxy (?? TODO or fix serialization to convert directly to date?)
  if (datish instanceof Object) {
    if (datish.seconds && datish.nanoseconds)
      return new Date(datish.seconds * 1000 + datish.nanoseconds / 1000000)

    // This one is what our doReviewJustThePage returns as review.meta.updatedAt...
    if (datish._seconds && datish._nanoseconds)
      return new Date(datish._seconds * 1000 + datish._nanoseconds / 1000000)
  }

  // ... finally, somehow w/ SSG + superjson serialization I'm getting stringified (toISOstring) versions from the server (once, then become normal)
  // that look like: 2021-03-22T20:57:23.892Z
  try {
    return parseISOString(datish)
  } catch (e) {
    console.warn(
      'dateFromDatish given non-date input with no toDate fn:',
      typeof datish,
      inspectObject(datish),
    )

    return null
  }
}

// Handle comparing dates OR firestore timestamp objects
export const sameDate = (a, b) => {
  if (!a || !b) return false
  const dateA = dateFromDatish(a)
  const dateB = dateFromDatish(b)
  if (!dateA || !dateB) return false

  return dateFromDatish(a).toString() == dateFromDatish(b).toString()
}
