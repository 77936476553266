import { cn } from 'common/utils'
import routes from 'common/routes'

import UniversalLink from 'universal/UniversalLink'
import { Fragment } from 'react'

export const Hashtags = ({
  hashtags = [],
  prefix,
  className,
  tagClassName,

  // Can remove if fix ext write mode share review UI to have space-x-4 take effect without this...
  spacer,

  ...props
}) =>
  hashtags?.length === 0 ? null : (
    <div className={cn(prefix && 'flex flex-wrap', className)}>
      {prefix}
      {hashtags.map((hashtag) => {
        const tag = hashtag.value || hashtag // Handle passing in array of hashtag _options_ or actual values
        return (
          <Fragment key={tag}>
            <Hashtag key={tag} tag={tag} {...props} className={tagClassName} />
            {spacer && <span className="w-4" />}
          </Fragment>
        )
      })}
    </div>
  )

// assumes already run through stringToHashtag
export const Hashtag = ({ tag, link, className }) => {
  const classes = cn('k-span', className)

  if (!link) return <span className={classes}>#{tag}</span>

  return <UniversalLink href={routes.hashtagUrl(tag)} className={classes} text={`#${tag}`} />
}

export default Hashtags
