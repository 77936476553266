import { cn, plur } from 'common/utils'
import MarkdownText from 'cc/MarkdownText'
import Hashtags from 'cc/Hashtags'

import VoteEmote from 'cc/VoteEmote'
import { OldLogo } from 'cc/Logo'
import Avatar from 'cc/Avatar'
import TimeAgo from 'cc/TimeAgo'

import { normalizeReview } from 'common/utils'
import ConditionalWrap from 'cc/ConditionalWrap'

import UniversalLink, { UniversalClickableDiv } from 'universal/UniversalLink'
import routes from 'common/routes'

const colors = {
  divider: [
    'mb-5 from-red-800 to-red-500',
    'from-gray-800 via-gray-400 to-gray-800',
    'mt-5 from-green-500 to-green-900',
  ],
  triColor: ['text-red-600', 'text-gray-600', 'text-green-600'],
  tri: [
    '-translate-x-1/2 translate-y-[22px] rotate-180',
    '-translate-x-1/2 rotate-90',
    'ml-[4px] -translate-x-1/2 -translate-y-[22px]',
  ],
  bg: ['bg-red-50', 'bg-gray-100', 'bg-green-50'],
  comment: ['text-red-900', 'text-gray-900', 'text-green-900'],
}

const ReviewCardWithContext = ({
  review,
  user,
  className,
  title,
  onReviewPage,

  // Children are rendered as additional "what was reviewed" context under the review body
  children,

  // Can override the context (left side) of the card explicitly
  ContextComponent,
  skipLinks,
  skipStaff,

  // Just for the featured review in the extension
  bylineSuffix,

  // Just for the shareable page image (don't save the relative date to an image)
  skipByline,

  // Fix this - shouldn't be handled this way
  pageKey,
}) => {
  const { vote, comment, hashtags, meta } = normalizeReview(review)

  const { domainKey } = meta

  const contextClasses = `p-4 flex flex-col flex-shrink-0 items-center justify-center w-[200px] h-full rounded-l-md ${
    skipLinks ? '' : 'hover:bg-gray-100'
  }`
  const wrapperClasses = 'flex flex-grow flex-col p-4 pl-0 h-full rounded-r-md'

  return (
    <div className={cn('flex', className)}>
      {ContextComponent ? (
        <ContextComponent domainKey={domainKey} pageKey={pageKey} className={contextClasses} />
      ) : user ? (
        <ContextualUser user={user} className={contextClasses} skipLinks={skipLinks} />
      ) : skipStaff ? null : (
        <div className="w-4 flex-shrink-0" />
      )}
      <div className="flex flex-grow">
        {skipStaff ? null : <ReviewStaff vote={vote} />}
        <ConditionalWrap
          condition={!skipLinks && !onReviewPage}
          wrap={(kid) => (
            <UniversalClickableDiv
              href={user ? routes.reviewUrl(user, domainKey, pageKey) : routes.siteUrl(domainKey)}
              className={cn(wrapperClasses, 'hover:bg-gray-100 cursor-pointer')}
            >
              {kid}
            </UniversalClickableDiv>
          )}
          elseWrap={(kid) => <div className={wrapperClasses}>{kid}</div>}
        >
          <div className="text-center text-xl mb-2">{title}</div>
          <ReviewContent
            review={review}
            onReviewPage={onReviewPage}
            bylineSuffix={bylineSuffix}
            skipByline={skipByline}
          />
          {onReviewPage ? (
            <div className="p-4 pb-1 flex flex-col gap-4 items-center lg:flex-row">{children}</div>
          ) : (
            <ReviewDetails meta={meta} />
          )}
        </ConditionalWrap>
      </div>
    </div>
  )
}

const ContextualUser = ({ user, className, skipLinks }) => {
  return (
    <ConditionalWrap
      condition={!skipLinks}
      wrap={(kid) => (
        <UniversalLink href={routes.userUrl(user)} className={cn(className, 'h-auto')}>
          {kid}
        </UniversalLink>
      )}
      elseWrap={(kid) => <div className={className}>{kid}</div>}
    >
      <>
        <Avatar user={user} size="128" className="border-2 border-gray-600 rounded-full mb-4" />
        <div className="flex items-end">
          <span className="text-lg font-light text-gray-600 pr-[2px]">@</span>
          <span className="text-2xl text-gray-800">{user.handle}</span>
        </div>
        <div className="text-base font-light text-gray-700 text-center leading-tight">{user.tagline}</div>
      </>
    </ConditionalWrap>
  )
}

export const ReviewStaff = ({ vote, className }) => {
  const justification = vote > 0 ? 'justify-start' : vote < 0 ? 'justify-end' : 'justify-center'

  return (
    <div
      className={cn(
        'w-1 flex flex-col min-h-max bg-gradient-to-b ',
        className,
        justification,
        colors.divider[vote + 1],
      )}
    >
      {/* <VoteEmote vote={vote} className="w-8" /> */}
      <OldLogo
        upClassName={colors.triColor[vote + 1]}
        downClassName="text-transparent"
        className={cn('h-8 w-8', colors.tri[vote + 1])}
      />
    </div>
  )
}

export const ReviewContent = ({ review, onReviewPage, bylineSuffix, skipByline }) => {
  const { vote, comment, hashtags, meta } = normalizeReview(review) // TODO:PACKET - why needed, should it be normalized in the getter?

  return (
    <div
      className={cn(
        colors.bg[vote + 1],
        'p-4 border-2 rounded-r-md border-l-0 w-full flex flex-col justify-between overflow-x-auto space-y-4',
      )}
    >
      {comment?.length ? (
        <div className="text-gray-700 flex relative">
          <div className="absolute text-4xl font-serif" aria-hidden="true">
            &ldquo;
          </div>
          <div
            className={cn(
              'ml-5 prose',
              onReviewPage ? 'text-xl leading-normal' : 'max-h-32 overflow-y-auto leading-snug',
            )}
          >
            <MarkdownText text={comment} />
          </div>
        </div>
      ) : null}

      <div className="flex flex-col space-y-2">
        {hashtags?.length ? (
          <Hashtags
            hashtags={hashtags}
            className="text-base md:text-xl flex flex-wrap w-full space-x-1 md:space-x-2"
            tagClassName="text-gray-800"
            spacer
          />
        ) : null}

        {skipByline ? null : (
          <div className="text-xs italic text-gray-400 text-left">
            Viewed
            <span className="whitespace-nowrap"> {meta.updatedAt && <TimeAgo datetime={meta.updatedAt} />} </span>
            {bylineSuffix}
          </div>
        )}
      </div>
    </div>
  )
}

const ReviewDetails = ({ meta }) => {
  const { numRuleReviews = 0, numPageReviews = 0 } = meta || {}
  const numReviews = numRuleReviews + numPageReviews

  return numReviews > 0 ? (
    <div className="mt-1 mx-2 text-xs text-light flex justify-end items-center text-gray-400">
      +{plur(numReviews, 'page review')}
    </div>
  ) : null
}

export default ReviewCardWithContext
