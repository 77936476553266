import { Fragment } from 'react'
import Head from 'next/head'
import config from 'common/config'
import { compact, isArray, endsWith } from 'lodash'
import { dateStringFromDatish } from 'common/utils'

// Note: function b/c next/head doesn't work if there's a *component* layer between Head and the actual tag
const makeMeta = (prop, rawVal) => {
  const val =
    prop && endsWith(prop, '_time') ? dateStringFromDatish(rawVal) : rawVal
  if (!val || !val.length) return null

  // If property includes :, assume it's fully qualified. Otherwise, prepend 'og:' and convert double-underscores to ':'.
  // (because can't pass : in component-style prop names). Examples:
  //    fb:app_id -> fb:app_id
  //    profile__username -> profile:username
  //    article__published_time -> article:published_time
  const fqProp = prop.includes(':')
    ? prop
    : compact([prop.includes('__') ? null : 'og', ...prop.split('__')]).join(
        ':',
      )

  if (!isArray(val))
    return <meta property={fqProp} content={val} key={fqProp} />

  // Support arrays (e.g. article tags) by passing array of values directly to makeMeta
  return (
    <Fragment key={`${prop}-array`}>
      {val.map((value, idx) => (
        <meta property={fqProp} content={value} key={[fqProp, idx].join('-')} />
      ))}
    </Fragment>
  )
}

// Make it easy to add different meta tags from different parts of the code (see domain for example where we don't have the hashtags when first render)
export const ExtraOpenGraph = ({ prop, val }) => (
  <Head>{makeMeta(prop, val)}</Head>
)

const OpenGraph = ({ pageTitle, ...props }) => {
  const rawTitle = pageTitle || props.title || config.appName
  const title =
    rawTitle.indexOf(config.appName) === -1
      ? [rawTitle, config.appName].join(' | ')
      : rawTitle

  return (
    <Head>
      <title>{title}</title>

      {Object.entries(props).map(([prop, val]) => makeMeta(prop, val))}
      {props.url?.length && (
        <link rel="canonical" key="canonical" href={props.url} />
      )}
      {makeMeta('og:site_name', config.appName)}
      {makeMeta('fb:app_id', config.fbAppId)}
    </Head>
  )
}

export default OpenGraph
