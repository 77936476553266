import OriginalTimeAgo from 'timeago-react'
import { dateFromDatish } from 'common/utils'

const TimeAgo = ({ datetime, ...props }) => {
  if (!datetime) return null

  try {
    const dt = dateFromDatish(datetime)
    return dt ? <OriginalTimeAgo title={dt.toLocaleString()} datetime={dt} {...props} /> : null
  } catch (e) {
    return null
  }
}

export default TimeAgo
