import { cn } from 'common/utils'
import { useHasMounted } from 'common/hooks'

import InstallExtensionButtonset from 'components/marketing/InstallExtensionButtonset'

// https://tailblocks.cc/
const GetItHere = ({ className }) => {
  const hasMounted = useHasMounted()
  if (!hasMounted) return null

  return (
    <section
      className={cn(
        'text-gray-600 body-font',
        '-skew-y-2 -mt-12 border-t border-b',
        className,
      )}
    >
      <div
        className={cn(
          'container flex flex-col items-center justify-center px-5 py-24 mx-auto',
          'skew-y-2 pb-24 z-10',
        )}
      >
        <img
          className="object-cover object-center w-5/6 mb-10 rounded lg:w-2/6 md:w-3/6"
          alt="hero"
          src="https://dummyimage.com/720x600"
        />
        <div className="flex flex-col items-center w-full mb-16 text-center md:w-2/3">
          <h1 className="mb-4 text-3xl font-medium text-gray-900 title-font sm:text-4xl">
            Knausgaard typewriter readymade marfa
          </h1>

          <p className="mb-8 leading-relaxed">
            Kickstarter biodiesel roof party wayfarers cold-pressed. Palo santo
            live-edge tumeric scenester copper mug flexitarian. Prism vice offal
            plaid everyday carry. Gluten-free chia VHS squid listicle artisan.
          </p>

          <InstallExtensionButtonset />
        </div>
      </div>
    </section>
  )
}

export default GetItHere
